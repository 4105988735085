<template>
  <div>
    <b-card title="Hello 🚀">
      <b-card-text>داشبورد مدیریت روم تور</b-card-text>

    </b-card>


  </div>
</template>

<script>
import { BCard, BCardText, BLink } from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BCardText,
    BLink,
  },
}
</script>

<style>

</style>
